<template>
  <div></div>
</template>

<script>
 import { setSessionToken } from '@/plugins/cookie'
export default {
  name: 'cooperation',
  data() {
  },
  mounted(){
    let url = window.location.hash
    let token = url.split('=')[1]
    this.$store.state.token = token
    setSessionToken(token)
    window.location.href = '#/'
    this.$router.push({ path: '/', query: { changeToken: true } })
    location.reload()
  }
}
</script>
